import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import ProductDetail from "../../components/products/productDetail"
import ProductCatalog from "../../components/products/productCatalog"
import Toptitle from "../../components/toptitle"

const Mikiri = () => (
  <Layout>
    <SEO title="見切り | 製品紹介" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Mikiri</Toptitle>
        <div className="w-full pt-32 lg:mt-16 lg:pt-0">
          <div className="w-full md:flex">
            <StaticImage
              src="../../images/products/mikiri.jpg"
              alt="見切りの事例"
              className="w-full md:w-1/2"
            />
            <div className="w-full bg-secondery py-8 px-12 md:w-1/2">
              <h2 className="py-2 font-mincho text-2xl text-primary">見切り</h2>
              <p className="font-gothic leading-loose">
                クロスの差し目地や壁見切り、天井-壁の入隅見切りなどをはじめ、
                <br />
                その他にも耐熱性能を活かし、間接照明の周辺材料やカーテンボックスなどにも利用いただけます。
              </p>
            </div>
          </div>
        </div>
        <div className="mx-2 mt-32 grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 lg:mx-0">
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/mikiri/am-42a.png" />
            <ProductDetail name="AM-42a" size="100×30" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/mikiri/am-43b.png" />
            <ProductDetail name="AM-43b" size="150×150" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/mikiri/am-44a.png" />
            <ProductDetail name="AM-44a" size="150×20" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/mikiri/am-45a.png" />
            <ProductDetail name="AM-45a" size="10×25" />
          </div>
          <div className="border-b border-primarySupport pb-4">
            <StaticImage src="../../images/products/product/mikiri/am-46a.png" />
            <ProductDetail name="AM-46a" size="10×50" />
          </div>
        </div>
        <ProductCatalog />
      </section>
    </div>
  </Layout>
)

export default Mikiri
